import getTokensFromStorage from '../Auth/AuthToken';
import { handleApiError } from '../../Middleware/API/apiRequest';
import { createErrorResponse, handleResponse } from '../Common/apiResponse';

const GPT_BASE_URL = `${process.env.GPT_BASE_URL}`;
const WMS_API_BASE_URL = `${process.env.WMS_API_BASE_URL}`;
const SMART_RAG_API_BASE_URL = `${process.env.SMART_RAG_API_BASE_URL}`;

let header = {
  Accept: 'application/json',
};

/**
 * Generic function to call an API.
 * @async
 * @param {string} url - The URL for the API request.
 * @param {string} method - The HTTP method for the request.
 * @param {Object} [data=null] - The request body data.
 * @param {Object} [customHeaders={}] - Custom headers to include in the request.
 * @returns {Promise<Object>} The response object.
 * @throws {Error} If an error occurs during the API call.
 */
export const callAPI = async (url, method, data, customHeaders = {}) => {
  const { accessToken } = getTokensFromStorage();
  if (accessToken) {
    header = { ...header, Authorization: `Bearer ${accessToken}` };
  }

  const mergedHeaders = { ...header, ...customHeaders };

  const requestOptions = {
    method,
    headers: mergedHeaders,
    body: data !== undefined ? data : null,
  };

  try {
    const response = await fetch(url, requestOptions);
    return await response.json();
  } catch (error) {
    handleApiError(error);
    return null;
  }
};

/**
 * Creates FormData from a given data object.
 * @param {Object} data - The data to convert into FormData.
 * @returns {FormData} The FormData object.
 */
export const createFormData = (data) => {
  const formData = new FormData();
  if (data !== undefined) {
    Object.keys(data).forEach((key) => {
      if (key !== 'new_files') {
        formData.append(key, data[key]);
      }
    });
    if (data.new_files && data.new_files.length > 0) {
      data.new_files.forEach((file) => {
        formData.append('new_files[]', file, file.name);
      });
    }
  }
  return formData;
};

/**
 * Makes a GPT API call.
 * @async
 * @param {string} url - The URL for the API request.
 * @param {string} [method='GET'] - The HTTP method for the request.
 * @param {Object} [data=null] - The request body data.
 * @returns {Promise<Object>} The response object.
 * @throws {Error} If an error occurs during the API call.
 */
export const callGptApi = async (url, method = 'GET', data = null) => {
  const apiKey = localStorage.getItem('openai_api_key') || process.env.REACT_APP_OPENAI_API_KEY;

  const headers = {
    Authorization: `Bearer ${apiKey}`,
    'OpenAI-Beta': 'assistants=v2',
  };
  if (!(data instanceof FormData)) {
    headers['Content-Type'] = 'application/json';
  }

  const config = {
    method,
    headers,
    body: data ? (data instanceof FormData ? data : JSON.stringify(data)) : null,
  };

  try {
    const response = await fetch(`${GPT_BASE_URL}${url}`, config);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return data?.stream ? response : await response.json();
  } catch (error) {
    throw new Error(`Fetch error: ${error.message}`);
  }
};

/**
 * Makes a Blue Yonder WMS API call.
 * @async
 * @param {string} url - The URL for the API request.
 * @param {string} [method='GET'] - The HTTP method for the request.
 * @param {Object} [data=null] - The request body data.
 * @returns {Promise<Object>} The response object.
 * @throws {Error} If an error occurs during the API call.
 */
export const BYApiCall = async (url, method = 'GET', data = null) => {
  const mergedHeaders = { ...header, 'Content-Type': 'application/json' };
  const config = {
    method,
    headers: mergedHeaders,
    body: data ? JSON.stringify(data) : null,
  };

  try {
    const response = await fetch(`${WMS_API_BASE_URL}${url}`, config);

    const jsonResponse = await response.json();
    // Check if the payload exists and is not empty
    if (jsonResponse && jsonResponse.payload && Object.keys(jsonResponse.payload).length > 0) {
      return jsonResponse;  // Return the payload if it's not empty
    }
    if (jsonResponse.status_code===200){
      return jsonResponse;
    }
  } catch (error) {
    throw new Error(`Fetch error: ${error.message}`);
  }
};


/**
 * Makes a SmartRAG API call.
 * @async
 * @param {string} url - The URL for the API request.
 * @param {string} [method='GET'] - The HTTP method for the request.
 * @param {Object} [data=null] - The request body data.
 * @param {Object} [customHeaders={}] - Custom headers to include in the request.
 * @param isStreaming
 * @returns {Promise<Object>} The response object.
 * @throws {Error} If an error occurs during the API call.
 */
export const SmartRagApiCall = async (url, method = 'GET', data = null, customHeaders = {}, isStreaming = false) => {
  const headers = { header, ...customHeaders };

  if (data instanceof FormData) {
    delete headers['Content-Type'];
  }

  const config = {
    method,
    headers,
  };

  if (data) {
    config.body = data instanceof FormData ? data : JSON.stringify(data);
  }

  try {
    const response = await fetch(`${SMART_RAG_API_BASE_URL}${url}`, config);
    return handleResponse(response, isStreaming);
  } catch (error) {
    return createErrorResponse(error.message || 'An error occurred during the API call.');
  }
};
