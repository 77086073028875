import React from 'react';
import { Route } from 'react-router-dom';
import { NetworkStatus } from 'smart-react';
import RoutesItems from './RoutesItems';
import BaseLayoutHOC from '../Layouts/BaseLayoutHOC';

const BaseTileLayout = React.lazy(() => import('../Layouts/BaseTileLayout'));
const BaseCardLayout = React.lazy(() => import('../Layouts/BaseCardLayout'));
// Wrap Providers around specfied layout
const CardLayout = BaseLayoutHOC(BaseCardLayout);
const TileLayout = BaseLayoutHOC(BaseTileLayout);
const EmptyLayout = BaseLayoutHOC(({ children }) => (
  <div className="emptyLayout">
    <div className="hide-network-icon">{/* <NetworkStatus /> */}</div>

    <div>{children}</div>
  </div>
));
const AuthEmptyLayout = EmptyLayout;

/**
 * List Layout Components
 */
const components = {
  CardLayout,
  TileLayout,
  EmptyLayout,
  AuthEmptyLayout,
};

/**
 * return Routes wrapped with layout component
 * @param {String} layout
 * @returns {array} return routes
 */
const getRoutes = (layout) => {
  const routesLayout = RoutesItems.filter(
    (item) => item.layouts === layout,
  ).map((item) => (
    <Route exact key={item.text} path={item.route} element={item.element} />
  ));
  let LayoutComponent = components[layout];
  return (
    <Route path="/" element={<LayoutComponent />}>
      {routesLayout}
    </Route>
  );
};
export default getRoutes;
