/**
 * Handles the API response.
 * @param {Response} response - The fetch response object.
 * @param {boolean} isStreaming - Whether the request is for streaming.
 * @returns {Promise<Object>} The response object.
 */
export const handleResponse = async (response, isStreaming) => {
  if (isStreaming) {
    return {
      isSuccess: response.ok,
      data: response,
      message: response.ok ? 'Streaming response started!' : 'Error in streaming response.',
    };
  }

  switch (response.status) {
    case 200:
    case 201:
      return createSuccessResponse(await response.json(), 'Request was successful!');
    case 400:
      return createErrorResponse('Bad Request: The server could not understand the request.');
    case 401:
      return createErrorResponse('Unauthorized: Access is denied due to invalid credentials.');
    case 404:
      return createErrorResponse('Not Found: The requested resource could not be found.');
    case 500:
      return createErrorResponse('Internal Server Error: The server encountered an error.');
    default:
      return createErrorResponse('An unexpected error occurred.');
  }
};

/**
 * Creates a success response object.
 * @param {Object} data - The response data.
 * @param {string} message - The success message.
 * @returns {Object} The success response object.
 */
export const createSuccessResponse = (data, message) => ({
  isSuccess: true,
  data,
  message,
});

/**
 * Creates an error response object.
 * @param {string} message - The error message.
 * @returns {Object} The error response object.
 */
export const createErrorResponse = (message) => ({
  isSuccess: false,
  message,
});
